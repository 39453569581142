@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
  url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
  url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
  url("../fonts/Inter-Bold.woff") format("woff");
}

$white: #ffffff;
$black: #262626;

$dark: #111B59;
$dark-blue: #303F9F;
$link-blue: #2C47F0;
$light-blue: #E7EAFF;
$blue: #5D70EB;
$dark-gray: #7B7B7B;
$gray: #C1C1C1;
$light-gray: #F4F4F4;
$purple: #C22671;
$red: #E32929;
$yellow: #F9A604;

$font-family: 'Inter', sans-serif;

$font-size: 16px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$base-body-font-family: $font-family;
$base-body-font-weight: $font-weight-regular;
$base-body-font-size: $font-size;
$global-font-family: $font-family;
$global-color: $black;
$global-link-color: $link-blue;
$global-link-hover-color: $purple;

$section-padding-vertical: 40px;
$section-padding-vertical-m: 48px;

$section-large-padding-vertical: 40px;
$section-large-padding-vertical-m: 64px;

$section-primary-background: $dark-blue;

$container-padding-horizontal: 16px;
$container-padding-horizontal-s: 24px;
$container-padding-horizontal-m: 48px;

$grid-gutter-horizontal: 16px;
$grid-gutter-horizontal-l: 24px;
$grid-gutter-vartical-l: 24px;
$grid-gutter-vertical: 16px;

$grid-large-gutter-horizontal: 24px;
$grid-large-gutter-horizontal-l: 24px;

$grid-medium-gutter-horizontal: 16px;
$grid-medium-gutter-vertical: 8px;

$margin-small-margin: 8px;
$margin-margin: 16px;
$margin-medium-margin: 24px;
$margin-large-margin: 32px;
$margin-large-margin-l: 32px;
$margin-xlarge-margin: 32px;
$margin-xlarge-margin-l: 40px;

$base-margin-vertical: 16px;
$base-heading-font-weight: $font-weight-bold;
$base-h1-font-size-m: 32px;
$base-h1-font-size: 24px;
$base-h2-font-size-m: 24px;
$base-h2-font-size: 20px;
$base-h3-font-size: 20px;
$base-h4-font-size: 16px;
$base-h5-font-size: 14px;
$base-h6-font-size: 12px;

@mixin hook-heading-misc {
  h3, .uk-h3 {
    @media screen and (max-width: 959px) {
      font-size: 18px;
    }
  }
}

$breadcrumb-divider: '•';
$breadcrumb-divider-color: #808080;
$breadcrumb-divider-margin-horizontal: 16px;
$breadcrumb-item-font-size: 12px;
@mixin hook-breadcrumb {
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 100px;
  margin-bottom: -100px;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  > li {
    > a {
      color: $black;
      font-size: 12px;
      &:hover {
        color: $blue;
      }
    }
    &:last-child > span {
      color: $gray;
      font-size: 12px;
    }
  }
   > :nth-child(n+2):not(.uk-first-column)::before {
     font-size: 10px;
  }
  @media screen and (max-width: 959px) {
    padding-left: 24px;
    > li:last-child {
      padding-right: 24px;
    }
  }
  @media screen and (max-width: 639px) {
    padding-left: 16px;
    > li:last-child {
      padding-right: 16px;
    }
  }
}

$accordion-content-margin-top: 0;
$accordion-item-margin-top: 24px;

@mixin hook-dotnav {
  a {
    border: 1px solid $dark-blue;
  }
}
$dotnav-item-active-background: $dark-blue;
$dotnav-item-hover-background: $blue;

$button-primary-background: $dark-blue;
$button-primary-color: $white;
$button-primary-hover-background: $blue;
$button-primary-hover-color: $white;
$button-padding-horizontal: 32px;

@mixin hook-button {
  font-weight: $font-weight-medium;
  border-radius: 8px;
  transition: all .3s ease;
  white-space: nowrap;
  line-height: 40px;
  font-size: 16px;
}

@mixin hook-form-misc {
  .uk-input {
    line-height: 38px;
    padding: 0 16px;
    border: 1px solid $gray;
    color: $black;
    background: $white;
    font-size: 14px;
    transition:  all .3s ease;
    border-radius: 4px;
    &:hover {
      border-color: $dark-blue;
    }
    &:focus {
      color: black;
      border-bottom-color: $dark-blue;
    }
    &::placeholder {
      color: $light-gray;
    }
  }
  .uk-textarea {
    padding: 16px;
    font-size: 16px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    min-height: 123px;
    height: 123px;
    transition:  all .3s ease;
    &:hover {
      border-color: $dark-blue;
    }
    &:focus {
      color: $black;
      border-color: $dark-blue;
    }
    &::placeholder {
      color: $light-gray;
    }
  }
}

$button-secondary-background: $white;
$button-secondary-color: $dark-blue;
$button-secondary-hover-color: $white;
$button-secondary-hover-background: $dark-blue;
$button-secondary-active-color: $white;
$button-secondary-active-background: $dark-blue;

$button-default-hover-background: $light-blue;
$button-default-hover-color: $dark-blue;
$button-default-active-background: $dark-blue;
$button-default-active-color: $white;

@mixin hook-button-default {
  border: 1px solid $dark-blue;
  border-radius: 8px;
  color: $dark-blue;
  line-height: 38px;
}


$pagination-item-active-color: $white;
$pagination-item-hover-color: $dark-blue;
@mixin hook-pagination-misc {
  .uk-pagination {
    margin: 0;
    padding-bottom: 100px;
    margin-bottom: -100px;
    border-radius: 2px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    > li {
      padding: 0;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
    a, span {
      font-size: 14px;
      font-weight: $font-weight-medium;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 4px;
      color: $gray;
      background: $light-gray;
    }
    .pagination-arrow a,
    .uk-disabled span {
      background: transparent;
      color: $black;
      &:hover {
        color: $blue;
        background: transparent;
      }
    }
    a {
      transition: all .3s ease;
      &:hover {
        color: $white;
        background: $dark-blue;
      }
    }
    .uk-active {
      span {
        background: $dark-blue;
      }
    }
  }
}

$offcanvas-bar-background: $white;
$offcanvas-bar-width-m: 320px;
$offcanvas-bar-width: 100%;
$offcanvas-bar-padding-vertical: 0;
$offcanvas-bar-padding-horizontal: 0;
$offcanvas-bar-padding-vertical-m: 0;
$offcanvas-bar-padding-horizontal-m: 0;

@mixin hook-offcanvas-bar {
  @media screen and (min-width: 639px) {
    width: 320px;
  }
}
